import React from 'react';
import Typed from 'react-typed';
import {FiArrowRight} from "react-icons/fi";
import {Link} from "react-router-dom";

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/tech-lock-faded.png" alt="About Images"/>
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Unique Business <br/> {" "}
                                    <Typed className="theme-gradient"
                                           strings={[
                                               "Embedded HW/SW.",
                                               "Asset Tracking.",
                                               "Business Intelligence.",
                                           ]}
                                           typeSpeed={80}
                                           backSpeed={5}
                                           backDelay={1000}
                                           loop
                                    />
                                </h2>
                                <p>Unlock new opportunities for your business with our diverse team. We improve
                                    real-time operational monitoring, boost efficiency, reduce costs, enhance safety,
                                    and elevate customer service. We work with companies of all sizes, from small
                                    businesses to Fortune 500 firms.</p>
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: 'start',
                                    gap: '35px',
                                    alignItems: "center"
                                }}>
                                    <img width={'180px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/interrail_logo_trans.png`}
                                         alt="IRE Company Logo"/>
                                    <img width={'150px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/csx_logo.png`}
                                         alt="CSX Company Logo"/>
                                    <img width={'120px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/fec_logo_trans_white.png`}
                                         alt="FEC Company Logo"/>
                                    <img width={'180px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/groupo_logo.svg`}
                                         alt="Groupo Company Logo"/>
                                    <img width={'150px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/crm_1.png`}
                                         alt="CRM Company Logo"/>
                                    <img width={'160px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/prp_logo.svg`}
                                         alt="PRP Company Logo"/>
                                    <img width={'200px'}
                                         src={`${process.env.PUBLIC_URL}/images/customerLogos/siemens-logo.png`}
                                         alt="Siemens Company Logo"/>
                                </div>
                                <div className="read-more-btn mt--40"
                                     style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <a
                                        className="btn-default"
                                        href="/pdfs/OEG_Capabilities_Overview-Printable.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span>View Capabilities</span>
                                    </a>
                                    <Link className="btn-default btn-icon" to="/about-us">More about
                                        Us <i className="icon"><FiArrowRight/></i></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOne
