import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";

const callToActionData = {
    title1: "Wayside Watcher",
    title2: "Check out our new future platform",
    subtitle: "Optimize Rail Operations with Real-Time Monitoring and Predictive Maintenance.",
    btnText: "Check it out",
}

const CalltoActionFive = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                <img width={'180px'}
                                     src={`${process.env.PUBLIC_URL}/images/logo/wayside-watcher.png`}
                                     alt="Wayside Watcher Platform Logo"/>
                            </div>
                            <div className="content text-center">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h2 className="title">{callToActionData.title1}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.link}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <div className="call-to-btn text-center">
                                        <a className="btn-default btn-medium round btn-icon" href="wayside-watcher">{callToActionData.btnText} <i
                                            className="icon"><FiArrowRight/></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFive;
